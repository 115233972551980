var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "cyui-card cyui-sign-contract-item"
  }, [_c('div', {
    staticClass: "cyui-card-header"
  }, [_c('div', {
    staticClass: "cyui-card-header-line"
  }, [_c('div', {
    staticClass: "cyui-card-header-content"
  }, [_c('div', {
    staticClass: "cyui-sign-contract-box"
  }, [_c('div', {
    staticClass: "cyui-sign-contract-box-header"
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "justify-content": "space-between"
    }
  }, [`${_vm.status}` === '1' ? _c('div', {
    staticStyle: {
      "color": "#D8B084",
      "font-weight": "bolder"
    }
  }, [_vm._v("等待管家确认")]) : `${_vm.status}` === '2' ? _c('div', {
    staticStyle: {
      "color": "#D8B084",
      "font-weight": "bolder"
    }
  }, [_vm._v("等待管家确认续签")]) : `${_vm.status}` === '4' ? _c('div', {
    staticStyle: {
      "color": "#D8B084",
      "font-weight": "bolder"
    }
  }, [_vm._v("等待客户签署")]) : `${_vm.status}` === '5' ? _c('div', {
    staticStyle: {
      "color": "#D8B084",
      "font-weight": "bolder"
    }
  }, [_vm._v("等待客户支付")]) : `${_vm.status}` === '9' ? _c('div', {
    staticClass: "cyui-list-title"
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.detailInfo.projectName) + " ")]), _c('br'), _c('span', [_vm._v(" 房号：" + _vm._s(_vm.detailInfo.apiContractVO ? _vm.detailInfo.apiContractVO.roomNo : '') + " ")])]) : `${_vm.status}` === '3' || `${_vm.status}` === '6' || `${_vm.status}` === '7' || `${_vm.status}` === '8' ? _c('div', {
    staticStyle: {
      "color": "#D40909",
      "font-weight": "bolder"
    }
  }, [_vm._v("签约已取消 ")]) : `${_vm.status}` === '10' ? _c('div', {
    staticStyle: {
      "color": "#D40909",
      "font-weight": "bolder"
    }
  }, [_vm._v("合同已到期 ")]) : _vm._e(), _vm.detailInfo.checkState === 1 ? _c('div', [_vm._v("审核中")]) : _vm.detailInfo.checkState === 2 ? _c('div', [_vm._v("审核通过")]) : _vm.detailInfo.checkState === 3 ? _c('div', [_vm._v("审核未通过")]) : _vm._e()])]), _c('div', {
    staticClass: "cyui-sign-contract-box-body"
  }, [_c('div', {
    staticClass: "cyui-list"
  }, [_c('div', {
    staticClass: "cyui-list-body"
  }, [_c('div', {
    staticClass: "cyui-list-item"
  }, [_c('div', {
    staticClass: "cyui-list-line"
  }, [_c('div', {
    staticClass: "cyui-list-label cyui-list-label-5"
  }, [_vm._v("申请时间：")]), _c('div', {
    staticClass: "cyui-list-content"
  }, [_vm._v(" " + _vm._s(_vm.detailInfo.createTime || '-'))])])]), `${_vm.status}` === '4' || `${_vm.status}` === '5' || `${_vm.status}` === '9' ? _c('div', {
    staticClass: "cyui-list-item"
  }, [_c('div', {
    staticClass: "cyui-list-line"
  }, [_c('div', {
    staticClass: "cyui-list-label cyui-list-label-5"
  }, [_vm._v("管家确认：")]), _c('div', {
    staticClass: "cyui-list-content"
  }, [_vm._v(" " + _vm._s(_vm.detailInfo.passTime || '-'))])])]) : _vm._e(), `${_vm.status}` === '5' || `${_vm.status}` === '9' ? _c('div', {
    staticClass: "cyui-list-item"
  }, [_c('div', {
    staticClass: "cyui-list-line"
  }, [_c('div', {
    staticClass: "cyui-list-label cyui-list-label-5"
  }, [_vm._v("签约完成：")]), _c('div', {
    staticClass: "cyui-list-content"
  }, [_vm._v(" " + _vm._s(_vm.detailInfo.successTime || '-'))])])]) : _vm._e(), `${_vm.status}` === '9' ? _c('div', {
    staticClass: "cyui-list-item"
  }, [_c('div', {
    staticClass: "cyui-list-line"
  }, [_c('div', {
    staticClass: "cyui-list-label cyui-list-label-5"
  }, [_vm._v("支付时间：")]), _c('div', {
    staticClass: "cyui-list-content"
  }, [_vm._v(" " + _vm._s(_vm.detailInfo.payPaidTime || '-'))])])]) : _vm._e(), `${_vm.status}` === '3' || `${_vm.status}` === '6' || `${_vm.status}` === '7' || `${_vm.status}` === '8' || `${_vm.status}` === '10' ? [_c('div', {
    staticClass: "cyui-list-item"
  }, [_c('div', {
    staticClass: "cyui-list-line"
  }, [_c('div', {
    staticClass: "cyui-list-label cyui-list-label-5"
  }, [_vm._v(" " + _vm._s(`${_vm.status}` === '10' ? '失效时间：' : '取消时间：') + " ")]), _c('div', {
    staticClass: "cyui-list-content"
  }, [_vm._v(" " + _vm._s(_vm.detailInfo.failureTime || '-'))])])]), _c('div', {
    staticClass: "cyui-list-item"
  }, [_c('div', {
    staticClass: "cyui-list-line"
  }, [_c('div', {
    staticClass: "cyui-list-label cyui-list-label-5"
  }, [_vm._v(" " + _vm._s(`${_vm.status}` === '10' ? '失效原因：' : '取消原因：') + " ")]), _c('div', {
    staticClass: "cyui-list-content"
  }, [_vm._v(" " + _vm._s(_vm.detailInfo.failureMsg || '-'))])])])] : _vm._e()], 2)])])])])])]), `${_vm.status}` === '1' || `${_vm.status}` === '2' || `${_vm.status}` === '4' || `${_vm.status}` === '9' ? _c('div', {
    staticClass: "cyui-card-body"
  }, [_c('div', {
    staticClass: "cyui-list cyui-sign-contract-content"
  }, [_c('div', {
    staticClass: "cyui-list-body"
  }, [`${_vm.status}` === '1' || `${_vm.status}` === '2' ? _c('div', {
    staticClass: "cyui-list-item cyui-list-multiple"
  }, [`${_vm.status}` === '1' ? _c('div', {
    staticClass: "cyui-list-line"
  }, [_c('div', {
    staticClass: "cyui-list-content"
  }, [_c('div', [_vm._v(_vm._s(_vm.roomInfo.projectName || '-') + " " + _vm._s(_vm.roomInfo.roomTypeName || '-'))]), _c('div', [_vm._v(_vm._s(_vm.roomInfo.area || '-') + "㎡ " + _vm._s(_vm.roomInfo.houseType || '-') + " ")]), _c('div', [_vm._v(_vm._s(_vm.roomInfo.roomArea || '-') + "·" + _vm._s(_vm.roomInfo.sheet || '-') + " ")]), _c('div', [_vm._v(_vm._s(_vm.roomInfo.monthRentPrice || '-') + "元/月")])]), _c('div', {
    staticClass: "cyui-list-thumb"
  }, [_c('img', {
    attrs: {
      "src": _vm.roomInfo.coverImg,
      "alt": ""
    }
  })])]) : _vm._e(), `${_vm.status}` === '2' || `${_vm.status}` === '4' ? _c('div', {
    staticClass: "cyui-list-title"
  }, [_c('span', {
    staticClass: "cyui-icon"
  }, [_c('svg-icon', {
    attrs: {
      "icon-class": "house"
    }
  })], 1), _c('span', [_vm._v(" " + _vm._s(_vm.roomInfo.projectName) + " " + _vm._s(_vm.detailInfo.apiContractVO ? _vm.detailInfo.apiContractVO.roomNo : ''))])]) : _vm._e()]) : _vm._e()])]), `${_vm.status}` === '9' ? _c('div', {
    staticClass: "cyui-list cyui-sign-contract-content"
  }, [_c('div', {
    staticClass: "cyui-list-body"
  }, [_c('div', {
    staticClass: "cyui-list-item"
  }, [_c('div', {
    staticClass: "cyui-list-line"
  }, [_c('div', {
    staticClass: "cyui-list-content"
  }, [_c('div', [_vm._v("预计入住日期：" + _vm._s(_vm.detailInfo.apiContractVO.expectedDate || '-') + " ")]), _c('div', [_vm._v(" 租赁日期：" + _vm._s(_vm.detailInfo.apiContractVO.startDate + '~' + _vm.detailInfo.apiContractVO.endDate || '-') + " ")])])])])])]) : _vm._e()]) : _vm._e(), _c('div', {
    staticClass: "cyui-card-footer"
  }, [_c('div', {
    staticClass: "cyui-card-footer-line cyui-hairline-top"
  }, [_c('div', {
    staticClass: "cyui-card-footer-content"
  }, [_c('div', {
    staticClass: "cyui-sign-contract-box"
  }, [_c('div', {
    staticClass: "cyui-sign-contract-box-header"
  }, [_vm._v("签约主体")]), _c('div', {
    staticClass: "cyui-sign-contract-box-body"
  }, [_c('div', {
    staticClass: "cyui-list"
  }, [_c('div', {
    staticClass: "cyui-list-body"
  }, [_c('div', {
    staticClass: "cyui-list-item"
  }, [_c('div', {
    staticClass: "cyui-list-line"
  }, [_c('div', {
    staticClass: "cyui-list-label cyui-list-label-5"
  }, [_vm._v("姓名：")]), _c('div', {
    staticClass: "cyui-list-content"
  }, [_vm._v(" " + _vm._s(_vm.detailInfo.realName || '-'))])])]), _c('div', {
    staticClass: "cyui-list-item"
  }, [_c('div', {
    staticClass: "cyui-list-line"
  }, [_c('div', {
    staticClass: "cyui-list-label cyui-list-label-5"
  }, [_vm._v("手机号：")]), _c('div', {
    staticClass: "cyui-list-content"
  }, [_vm._v(" " + _vm._s(_vm.detailInfo.mobile || '-'))])])])])])])]), _c('div', {
    staticClass: "cyui-sign-contract-btns"
  }, [`${_vm.status}` === '1' || `${_vm.status}` === '2' || `${_vm.status}` === '4' ? _c('button', {
    staticClass: "cyui-btn cyui-btn-primary cyui-btn-block",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.handleCancelSignContract
    }
  }, [_vm._v(" 取消申请 ")]) : _vm._e(), `${_vm.status}` === '4' || `${_vm.status}` === '5' || `${_vm.status}` === '9' ? _c('button', {
    staticClass: "cyui-btn cyui-btn-primary cyui-btn-block",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.goDetail
    }
  }, [_vm._v(" 查看详情 ")]) : _vm._e(), `${_vm.status}` === '1' || `${_vm.status}` === '2' ? [_c('button', {
    class: ['cyui-btn', 'cyui-btn-primary', 'cyui-btn-block', _vm.detailInfo.isInBlacklist === 1 ? 'disabled' : ''],
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.goDetail
    }
  }, [_vm._v(" 确认信息 ")])] : _vm._e(), _vm.detailInfo.contractId && (`${_vm.status}` === '5' || `${_vm.status}` === '9') ? _c('button', {
    staticClass: "cyui-btn cyui-btn-primary cyui-btn-block",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.handleViewContract
    }
  }, [_vm._v(" 查看合同 ")]) : _vm._e(), _vm.detailInfo.confirmCheckIn == 0 && `${_vm.status}` === '9' ? _c('button', {
    staticClass: "cyui-btn cyui-btn-primary cyui-btn-block",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.handelClick_checkIn
    }
  }, [_vm._v(" 确认办理入住 ")]) : _vm._e(), `${_vm.status}` === '3' || `${_vm.status}` === '6' || `${_vm.status}` === '10' ? _c('a', {
    staticClass: "cyui-btn cyui-btn-primary cyui-btn-block",
    attrs: {
      "href": `tel:${_vm.detailInfo.mobile}`
    }
  }, [_vm._v(" 联系客户 ")]) : _vm._e()], 2)])])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };