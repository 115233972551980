<template>
  <div class='cyui-layout-wrapper'>
    <cyui-layout>
      <template #header>
        <cyui-header v-if='isCustomHeader' :title='headerTitle'
                     @go-back='goBack' @on-close='handleClosePage'>
          <template #headerRight>
            <div></div>
          </template>
        </cyui-header>
        <div class='cyui-sign-contract-navbar'>
          <van-search v-model='searchKey.phoneOrName' placeholder='请输入姓名或手机号' show-action @search='onSearch'>
            <template #action>
              <div @click='onSearch'>搜索</div>
            </template>
          </van-search>
          <cyui-navbar v-model='categoryInfo.value' :swipe-threshold='2'
                       show-title-number :list-data='categoryInfo.listData'
                       @change='handleTabChange'>
          </cyui-navbar>
        </div>
      </template>
      <template #default>
        <div class='cyui-scroller-panel'>
          <cyui-scroller class='wrapper' ref='scroller'
                         :scrollbar='scrollbarConfig'
                         :pullDownRefresh='pullDownRefreshConfig'
                         :pullUpLoad='pullUpLoadConfig' :startY='parseInt(startY)'
                         @pullingDown='handlePullingDown' @pullingUp='handlePullingUp'>
            <template #default>
              <div class='cyui-sign-contract-list' v-if='listData.length'>
                <sign-contract-list-item v-for='(item, index) in listData'
                                         :key='index' :detail-info='item' @go-detail='goDetail(item)'
                                         @on-view-contract='handleViewContract(item)'
                                         @confirm-check-in='handelClick_checkIn(item)'
                                         @on-cancel-sign-contract='handleCancelSignContract(item)'>
                </sign-contract-list-item>
              </div>
            </template>
          </cyui-scroller>
        </div>
        <no-data :msg='noDataMessage'
                 v-if='listData.length === 0 && isLoading === false'>
          <template #btn>
            <div
              class='cyui-btn cyui-btn-default cyui-btn-inline cyui-btn-radius'
              @click='handleRefreshPage' v-if='isRefreshPage'>
              刷新重试
            </div>
          </template>
        </no-data>
      </template>
      <template #footer>
        <div class='cyui-actionbar'>
          <div class='cyui-actionbar-item cyui-actionbar-btns'>
            <button
              type='button'
              class='cyui-btn cyui-btn-primary cyui-btn-block'
              @click="visible = true"
            >
              线下签约
            </button>
          </div>
        </div>
      </template>
    </cyui-layout>
    <!-- 弹窗 -->
    <van-action-sheet
      v-model="visible"
      :actions="list"
      @select="onSelect"
      cancel-text="取消"
      :close-on-click-overlay="false"
      close-on-click-action />
  </div>
</template>

<script>
  import { debounce } from 'lodash'
  import { mapGetters } from 'vuex'
  import common from '@mixins/common'
  import scrollList from '@mixins/scrollList'
  import getPageList from '@mixins/getPageList'
  import signContractMixins from './mixins/signContract'
  import filters from '@filters/filters'
  import { SignContractListItem } from './components'
  import { getListAPI, housekeeperCheckIn } from '@api/signContract'

  const categoryListData = [
    /* {
id: '',
label: '全部',
number: 0,
children: []
}, */
    {
      id: '1',
      label: '待处理',
      number: 0,
      children: []
    },
    {
      id: '2',
      label: '已完成',
      number: 0,
      children: []
    },
    {
      id: '3',
      label: '已失效',
      number: 0,
      children: []
    }
  ]

  export default {
    name: 'SignContractList',
    mixins: [common, filters, scrollList, getPageList, signContractMixins],
    components: {
      SignContractListItem
    },
    data() {
      return {
        headerTitle: '签约管理',
        categoryInfo: { // 分类数据
          value: '', //
          listData: categoryListData
        },
        initParams: { // 初始化参数
          page: 1,
          size: 20
        },
        searchKey: {}, // 搜索参数
        visible: false,
        list: [{ name: '新签', color: '#d2b28a' }, { name: '续签', color: '#d2b28a' }]
      }
    },
    props: {
      status: {
        type: [Number, String],
        default: '1'
      }
    },
    computed: {
      ...mapGetters('user', [
        'projectInfo'
      ])
    },
    methods: {
      // 进入详情
      goDetail(data) {
        let { contractId } = data
        this.$router.push({
          name: 'SignContractDetail',
          query: {
            contractId
          }
        })
      },
      // 查看合同
      handleViewContract: debounce(function(data) {
        let detailInfo = data
        window.open(detailInfo.oosPdf)
        /* this.$router.push({
          name: 'FileIndex',
          query: {
            fileUrl: detailInfo.oosPdf
          }
        }) */
      }, 200),
      // 确认办理入住
      handelClick_checkIn: debounce(function(data) {
        let { contractId } = data
        this.$dialog.confirm({
          title: '办理入住',
          message: '是否确认办理入住？',
          confirmButtonColor: '#D8B084'
        }).then(async () => {
          // on confirm
          await housekeeperCheckIn({
            contractId
          }).then(res => {
            this.$toast({
              message: '操作成功！',
              onClose: () => {
                this.initData()
              }
            })
          }).catch(() => {

          })
        }).catch(() => {
          // on cancel
        })
      }, 200),
      // 取消签约
      handleCancelSignContract: debounce(function(data) {
        let { contractId } = data
        this.$dialog.confirm({
          title: '取消签约',
          message: '是否确认取消签约？',
          confirmButtonColor: '#D8B084'
        }).then(async () => {
          // on confirm
          await this.cancelSignContractData({
            isShowLoading: true,
            contractId
          })
          this.$toast({
            message: '提交成功！',
            onClose: () => {
              this.initData()
            }
          })
        }).catch(() => {
          // on cancel
        })
      }, 200),
      handleTabChange({ name }) {
        if (name !== this.status) {
          this.$router.replace({
            name: 'SignContractList',
            query: {
              status: name
            }
          })
        }
      },
      async getListData({ isPullDown, isShowLoading } = { isPullDown: false, isShowLoading: true }) { // 获取列表数据
        try {
          this.isLoading = true
          if (isShowLoading) await this.$store.dispatch('base/SetLoading', true)
          let { status, listData } = this
          let { page, size,phoneOrName } = this.searchKey
          let { dcProjectId } = this.projectInfo
          let response = await getListAPI({
            page,
            size,
            phoneOrName,
            projectId: dcProjectId,
            type: status
          })
          let result = response.data
          // console.log(result)
          if (`${result.code}` === '10000') { // 请求成功
            let resultData = result.data || []
            // let hasMore = resultData.length > 0
            let hasMore = page < result.pages
            this.listData = !isPullDown
              ? [...listData, ...resultData]
              : [...resultData]
            if (!hasMore) {
              this.isLastPage = true
              // this.$toast('没有更多数据了！')
            }
            await this.handleCallback({
              isError: false,
              hasMore
            })
          } else {
            // 请求失败
            await this.handleCallback({
              isError: true,

              errorMessage: result.msg || '获取列表失败!'
            })
          }
        } catch (error) {
          // console.log(error)
          await this.handleCallback({
            isError: true,
            errorMessage: error.message || '获取列表失败!'
          })
        } finally {
          this.isLoading = false
          if (isShowLoading) await this.$store.dispatch('base/SetLoading', false)
        }
      },
      async initData() {
        await this.resetParams()
        await this.getListData()
      },
      onSearch() {
        this.resetParams()
        this.getListData()
      },
      onSelect(item) {
        const { name } = item
        this.$router.push({
          name: 'SignContractOffline',
          query: {
            contractType: name
          }
        })
      }
    },
    filters: {},
    watch: {
      initParams: {
        handler: function(newVal, oldVal) {
          if (JSON.stringify(newVal) !== JSON.stringify(this.searchKey)) {
            this.searchKey = JSON.parse(JSON.stringify(newVal))
          }
        },
        deep: true,
        immediate: true
      },
      status: {
        handler: function(newVal, oldVal) {
          this.searchKey = JSON.parse(JSON.stringify(this.initParams))
          this.categoryInfo.value = newVal
          this.initData()
        },
        immediate: true
      }
    },
    created() {
      const { token, projectId, projectName, userId, userName } = this.$route.query
      if (token && projectId) {
        this.$store.commit('user/SET_TOKEN', token)
        this.$store.commit('user/SET_USER_INFO', { dcProjectId: projectId, userId, userName, projectName })
        this.$store.commit('user/SET_PROJECT_INFO', { dcProjectId: projectId, projectName })
      }
    },
    mounted() {
    },
    beforeRouteEnter(to, from, next) {
      // console.log(to)
      // console.log(from)
      next()
    },
    beforeRouteUpdate(to, from, next) {
      // console.log(to)
      // console.log(from)
      next()
    }
  }
</script>

<style lang='less' scoped>
  @import '../../assets/styles/themes/default/index';
  @import '../../assets/styles/mixins/index';

  @signContractPrefixCls: ~'@{css-prefix}sign-contract';

  .@{css-prefix}scroller-panel {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    & /deep/ .pulldown-wrapper {
      top: 5px !important;
    }
  }

  .@{signContractPrefixCls} {
    &-navbar {
      padding: 0 5px;
      background-color: #ffffff;

      & /deep/ .cyui-navbar {
        background-color: #ffffff;

        &-body {
          .van-tabs {
            &.van-tabs--line {
              .van-tabs__wrap {
                .van-tab {
                  flex: none;
                  padding: 0 10px;

                  &.van-tab--active {
                    .@{css-prefix}navbar-title {
                      font-weight: normal;
                      color: #ffffff;
                      border-color: #d19c70;
                      background-color: #d19c70;

                      &-number {
                        background: #e5563f;
                      }
                    }
                  }
                }
              }
            }

            .van-tabs__nav--line {
              .van-tabs__line {
                display: none;
              }
            }
          }
        }

        &-title {
          position: relative;
          display: block;
          min-width: 74px;
          height: 26px;
          padding: 0 12px;
          line-height: 26px;
          font-size: 12px;
          color: #b9b9b9;
          text-align: center;
          border-radius: 14px;
          border: 1px solid #cfcfcf;
          background-color: #f1f1f1;

          &-number {
            position: absolute;
            top: 2px;
            right: 0;
            transform: translate3d(50%, -50%, 0);
            min-width: 18px;
            height: 18px;
            padding: 0 5px;
            line-height: 18px;
            border-radius: 18px;
            background: #e5563f;
          }
        }
      }
    }

    &-list {
      padding: 10px 10px 0;
      margin-left: 10px;
    }
  }

  .@{css-prefix}actionbar {
    position: relative;
    height: 65px;
    background-color: transparent;

    .@{css-prefix}actionbar-btns {
      padding: 10px 43px 15px;

      .@{css-prefix}btn {
        height: 40px;
        line-height: 40px;
        background-color: #FFFFFF;
        font-size: 15px;
        border: 1px solid #D1A36F;
        border-radius: 20px;
        color: #D1A36F;

        &.@{css-prefix}btn-primary {
          color: #FFFFFF;
          background-color: #D1A36F;
        }

        &.@{css-prefix}btn-info {
          color: #FFFFFF;
          background-color: #909399;
          border: 1px solid #909399;
        }

        &.@{css-prefix}btn-ghost {
        }
      }

      .@{css-prefix}btn + .@{css-prefix}btn {
        margin-left: 30px;
      }
    }
  }
</style>
