import request from '@utils/request'
import request_gd from '@utils/request_gd'
import { exp } from 'mathjs'
// import qs from 'qs'

/**
 * SignContract-获取列表数据
 * url:'/contract/getList'
 * method:GET
 * @Header Authorization: 登录接口返回的token
 * @Query params:{
 *   pageSize: '' //  分页大小
 *   pageNum: '' //  当前页数
 *   projectId: '' //  项目id
 *   status: '' //  进度 2:进行中 3:已完成 全部则不传
 * }
 * @returns {*}
 */
export const getListAPI = (params = {}) => {
  return request.get('/contract/getList', { params })
}

/**
 * SignContract-获取详情数据
 * url:'/contract/detail'
 * method:POST
 * @Header Authorization: 登录接口返回的token
 * @Query params:{
 *   projectId: '' // 项目ID
 *   contractId: '' // 合同ID
 *   roomTypeId: '' // 房型ID
 * }
 * @returns {*}
 */
export const getDetailAPI = (params = {}) => {
  return request({
    url: '/contract/detail',
    method: 'post',
    params
  })
}

/**
 * SignContract-管家取消签约
 * url:'/contract/refuse'
 * method:GET
 * @Header Authorization: 登录接口返回的token
 * @Query params:{
 *   contractId: '' // 合同ID
 * }
 * @returns {*}
 */
export const cancelSignContractAPI = (params = {}) => {
  return request.get('/contract/refuse', { params })
}

/**
 * SignContract-房源列表
 * url:'/contract/houseList'
 * method:POST
 * @Header Authorization: 登录接口返回的token
 * @Query params:{
 *   startDate: '' // 租赁开始日期(yyyy-MM-dd)
 *   endDate: '' // 租赁结束日期(yyyy-MM-dd)
 *   projectId: '' // 项目ID
 *   roomTypeName: '' // 房型名称
 * }
 * @returns {*}
 */
export const getHouseListAPI = (params = {}) => {
  return request.post('/contract/houseList', params)
}

/**
 * SignContract-确认信息
 * url:'/contract/confirm'
 * method:POST
 * @Header Authorization: 登录接口返回的token
 * @Query params:{
 * }
 * @returns {*}
 */
export const confirmSignContractAPI = (params = {}) => {
  return request({
    url: '/contract/confirm',
    method: 'post',
    headers: { 'INTERCEPT': 'false' },
    params
  })
}

/**
 * SignContract-续签确认信息
 * url:'/contract/renewalConfirm'
 * method:POST
 * @Header Authorization: 登录接口返回的token
 * @Query params:{
 * }
 * @returns {*}
 */
export const renewalConfirmSignContractAPI = (params = {}) => {
  return request({
    url: '/contract/renewalConfirm',
    method: 'post',
    headers: { 'INTERCEPT': 'false' },
    params
  })
}

/**
 * SignContract-获取合同结束时间
 * url:'/contract/getLastDate'
 * method:GET
 * @Header Authorization: 登录接口返回的token
 * @Query params:{
 *  startDate: '',
 *  monthNum: ''
 * }
 * @returns {*}
 */
export const getLastDateAPI = (params = {}) => {
  return request({
    url: '/contract/getLastDate',
    method: 'get',
    headers: { 'INTERCEPT': 'false' },
    params
  })
}

/*
* 批次列表
* */
export const getBatchList = (params = {}) => {
  return request_gd({
    url: '/project/getBatchList',
    method: 'get',
    params
  })
}

/*
* 成交来源列表
* */
export const getClinchDealSouceAPI = () => {
  return request({
    url: '/contract/getTenantSource',
    method: 'get'
  })
}
/*
* 职业
*  */
export const getProfessionAPI = (params = {}) => {
  return request({
    url: '/contract/getDictList',
    method: 'get',
    params
  })
}

/*
* 是否展示套餐价
* */
export const getPackagePriceAPI = (params) => {
  return request({
    url: '/contract/getPackageStatus',
    method: 'get',
    params
  })
}

/*
* 宠物押金
* */
export const getPetDepositAPI = (params = {}) => {
  return request({
    url: '/contract/getProjectPetDeposit',
    method: 'get',
    params
  })
}
/*
*管家办理入住
*
*/
export const housekeeperCheckIn = (data = {}) => {
  return request({
    url: 'contract/confirmCheckIn',
    method: 'post',
    headers: { 'INTERCEPT': 'false' },
    data
  })
}
/*
* 房间底价
* */
export const getFloorPriceAPI = (params = {}) => {
  return request({
    url: '/contract/getHouseMinAmount',
    method: 'get',
    params
  })
}
/*
* 特价房
* */
export const getSpecialRoomAPI = (data = {}) => {
  return request({
    url: '/contract/getRoomSpecial',
    method: 'post',
    data
  })
}
/*
* 上一次成交价
* */
export const getHousePriceByRentId = (params = {}) => {
  return request({
    url: '/contract/getHousePriceByRentId',
    method: 'get',
    params
  })
}
/*
* 是否进行审核
*  */
export const getCheckState = (data = {}) => {
  return request({
    url: '/contract/isCheckSuccess',
    method: 'post',
    data
  })
}
/*
* 是否进行审核
*  */
export const setCheck = (data = {}) => {
  return request({
    url: '/contract/checkSuccess',
    method: 'post',
    data
  })
}

/*
* 优惠卷方案列表
*  */
export const getCouponSchemeList = (params = {}) => {
  return request({
    url: '/coupon/getCouponProgrammeList',
    method: 'get',
    params
  })
}

/*
* 优惠卷列表
*  */
export const getCouponList = (params = {}) => {
  return request({
    url: '/coupon/getCouponCouponListOfProgramme',
    method: 'get',
    params
  })
}

/*
* 查询项目的允许出租天数
* */
export const getAllowedSigningDays = (params = {}) => {
  return request_gd({
    url: '/project/getAllowedSigningDays',
    method: 'get',
    params
  })
}

/**
 * 户型列表
 * **/
export const getHouseTypeListApi = (params = {}) => {
  return request({
    url: '/signContractOffLine/layoutList',
    method: 'get',
    params
  })
}

/**
 * 合同查询
 * **/
export const getRoomIdAPI = (data = {}) => {
  return request({
    url: '/signContractOffLine/getCanRenewRent',
    method: 'post',
    data
  })
}

/**
 * 线下签约
 * **/
export const confirmSignContractOfflineAPI = (data = {}) => {
  return request({
    url: '/signContractOffLine/applyForContractOrRenewal',
    method: 'post',
    data
  })
}

export const getOtherDaysByProject = (data = {}) => {
  return request({
    url: '/contract/getOtherDaysByProject',
    method: 'get',
    params: data
  })
}

export const getMonthPrice = (data = {}) => {
  return request({
    url: '/contract/calculateTotalAmount',
    method: 'post',
    data
  })
}
export const getCustomerReportUser = (params = {}) => {
  return request({
    url: '/contract/getCustomerReportUser',
    method: 'get',
    params
  })
}
export const getStewardsListByProject = (params = {}) => {
  return request({
    url: '/contract/stewardsListByProject',
    method: 'get',
    params
  })
}
export const getUserCouponList = (params = {}) => {
  return request({
    url: '/contract/getUserCouponList',
    method: 'get',
    params
  })
}

// 套餐列表
export const getPackageList = (params = {}) => {
  return request({
    url: '/contract/getPackageList',
    method: 'get',
    params
  })
}
// 套餐列表
export const getPtfwf = (params = {}) => {
  return request_gd({
    url: '/api/v1/sysFee/getPtfwf',
    method: 'get',
    params
  })
}

// 查询用户是否黑名单
export const getIsInBlacklistApi = (params = {}) => {
  return request.get('/tenant/blacklist/getIsInBlacklist', { params })
}

// 查询是否支持天数选择
export const getProjectAllowDayApi = (params = {}) => {
  return request.get('/contract/getProjectAllowDay', { params })
}
