
/**
 *  status
 *  1等待审核
 *  2管家通过
 *  3管家取消签署
 *  4客户待签署
 *  5/9（客户签署完成）
 *  6客户取消签署
 */
import filters from '@filters/filters'
import { Dialog } from 'vant'

export default {
  name: 'SignContractListItem',
  mixins: [filters],
  components: {},
  data () {
    return {}
  },
  props: {
    detailInfo: {
      type: Object,
      require: true,
      default () {
        return {}
      }
    }
  },
  computed: {
    status () { // 当前状态
      let { detailInfo } = this
      return detailInfo.status
    },
    roomInfo () { // 户型信息
      let { detailInfo } = this
      return detailInfo.baseRoomTypeEnt || {}
    }
  },
  methods: {
    goDetail () {
      if (this.detailInfo.isInBlacklist === 1) {
        Dialog.alert({
          title: '请注意！',
          message: '当前客户为受限客户，无法进行签约'
        }).then(() => {
          // on close
        })
        return
      }
      this.$emit('go-detail', this.detailInfo)
    },
    // 查看合同
    handleViewContract () {
      this.$emit('on-view-contract', this.detailInfo)
    },
    // 确认办理入住
    handelClick_checkIn () {
      console.log(this.detailInfo)
      this.$emit('confirm-check-in', this.detailInfo)
    },

    // 取消签约
    handleCancelSignContract () {
      this.$emit('on-cancel-sign-contract', this.detailInfo)
    },
    async initData () {
    }
  },
  filters: {},
  watch: {},
  created () {
    this.initData()
  },
  mounted () {
  }
}
